<template>
  <div class="page flex-col">
    <div class="container-body flex-col">
      <div  class="container">
      <!-- 中间部分标语 -->
      <div class="row block_3">
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/left-decorative-horizontal-bar.png"/>
          </p>
        </div>
        <div class="col-sm-4">
          <p class="text_6">图纸智能生成 设计方案 智能自动生成 概预算自动生成 审计决算自动生成</p>
        </div>
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/right-decorative-horizontal-bar.png"/>
          </p>
        </div>
      </div>
      </div>
      <!-- 设计平台 -->
      <div class="block_1 flex-col">
        <div  class="container">
        <div class="block_4 flex-col">
          <div class="text-wrapper_2 flex-col"><span class="text_7">我们能做什么</span></div>

          <!-- 设计流程4列显示,显示2行 -->
          <div class="services-block ontent content-center" id="services">
          <div class="container">
            <div class="row content-margin">
                <div class="col-md-3 col-sm-3 col-xs-12 item" v-for="(item0, index) in loopData0" :key="index" @click="btn(index+1)">
                  <img class="image_4" referrerpolicy="no-referrer" :src="item0.specialSlot1.lanhuimage0"/>
                  <p class="text_9" v-html="item0.lanhutext0"></p>
                  <img id="screen" referrerpolicy="no-referrer" :src="item0.specialSlot1.showmage0" v-if="menuIndex1==item0.slot1"/>
                </div>
            </div>
            <div class="row content-margin">
                <div class="col-md-3 col-sm-3 col-xs-12 item" v-for="(item1, index) in loopData1" :key="index" @click="btn(index+5)">
                  <img class="image_4" referrerpolicy="no-referrer" :src="item1.specialSlot1.lanhuimage1"/>
                  <p class="text_9" v-html="item1.lanhutext1"></p>
                  <img id="screen" referrerpolicy="no-referrer" :src="item1.specialSlot1.showmage0" v-if="menuIndex1==item1.slot1"/>
                </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
// import screenfull from 'screenfull/dist/screenfull';
export default {
  name: "CompartmentDesign",
  data() {
    return {
      menuIndex: 2,
      menuIndex1: 0,
      constants: {},
      loopData0: [
        {
          lanhutext0: 'CAD基准图纸智能解析<br/>识别相关性判定',
          specialSlot1: {
            lanhuimage0: require('../../assets/img/137.png'),
            showmage0:require('../../assets/img/1.png'),
          },
          slot1: 1
        },
        {
          lanhutext0: '智能绘制设备天线点位图',
          specialSlot1: {
            lanhuimage0:require('../../assets/img/138.png'),
            showmage0:require('../../assets/img/2.png'),
          },
          slot1: 2
        },
        {
          lanhutext0: '自动绘制网络拓扑图',
          specialSlot1: {
            lanhuimage0:require('../../assets/img/139.png'),
            showmage0:require('../../assets/img/3.png'),
          },
          slot1: 3
        },
        {
          lanhutext0: '自动绘制系统图',
          specialSlot1: {
            lanhuimage0:require('../../assets/img/140.png'),
            showmage0:require('../../assets/img/4.png'),
          },
          slot1: 4
        },
      ],
      loopData1: [
        {
          lanhutext1: '自动分区',
          specialSlot1: {
            lanhuimage1:require('../../assets/img/143.png'),
            showmage0: require('../../assets/img/5.png'),
          },
          slot1: 5
        },
        {
          lanhutext1: '可视化模拟仿真',
          specialSlot1: {
            lanhuimage1:require('../../assets/img/144.png'),
            showmage0: require('../../assets/img/6.png'),
          },
          slot1: 6
        },
        {
          lanhutext1: '设计方案智能生成<br/>(文本)',
          specialSlot1: {
            lanhuimage1:require('../../assets/img/145.png'),
            showmage0: require('../../assets/img/7.png'),
          },
          slot1: 7
        },
        {
          lanhutext1: '施工完成&nbsp;智能核算',
          specialSlot1: {
            lanhuimage1:
              require('../../assets/img/146.png')
          },
          slot1: 10
        }
      ],
    };
  },
  created() {
    localStorage.setItem("menuIndex", this.menuIndex);
  },

  methods: {

    // async btn(index) {
    //   // 先等待图片显示出来，再全屏展示
    //   await this.showImg(index);
    //   let element = document.getElementById("screen"); //指定全屏区域元素
    //   screenfull.toggle(element); //全屏显示
    // }, 

    // showImg(index){
    //   console.log("模块：" + index);
    //   this.menuIndex1 = index;
    //   // resolve(/*需要返回的数据*/);
    // },



    // showFullScreen(){
    //   let element = document.getElementById("screen"); //指定全屏区域元素
    //   screenfull.toggle(element); //全屏显示
    // },

  }
};
</script>
<style scoped lang="css" src="../../assets/compartmentDesign.css" />